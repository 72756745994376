import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog, MatTabGroup } from '@angular/material';
import { asap } from 'rxjs/internal/scheduler/asap';
import { FormUtilitiesService } from 'src/app/shared/services/form-utilities.service';
import { ElementsForm, FormEditSection, SectionForm, SubRequestForm } from 'src/app/shared/models/form-structure';
import { DataTransfer, FormContext, FormElement } from 'src/app/shared/enums';
import { Instance, InstanceContainer } from 'src/app/shared/models';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormSectionComponent } from '../../../data-designer/components/designer/datafront-components/form-section/form-section.component';
import { OriginationsService } from '../../../../shared/services/originations.service';
import { GlobalService } from '../../../../shared/services/global.service';
import { FormDataService } from '../../../../shared/services/form-data.service';
import { FunctionsService } from '../../../../shared/services/functions.service';
import { AlertService } from '../../../../shared/services/alerts.service';
import { completeModelWithDatatype } from '../../form.utils';
import { ModalTaskAttentionComponent } from '../modal-task-attention/modal-task-attention.component';
import { FormStorageService } from '../../form-storage/form-storage.service';
import { FormEditComponent } from '../form-edit/form-edit/form-edit.component';
import * as momento from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { MaskPipe } from 'src/app/ngx-mask';
import { FormFileUploaderSharedService } from '../../../../shared/services/form-file-uploader-shared.service';

// #region Constants

const VALUE_KEY = 'value';
const OBJECT_TYPE = 'object';
const SECTION_SHOW_KEY = 'show';

// #endregion

@Component({
  selector: 'scc-form-section-container',
  templateUrl: './form-section-container.component.html',
  styleUrls: ['./form-section-container.component.scss'],
  providers: [MaskPipe]
})
export class FormSectionContainerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() formTitle = '';
  @Input() formEditSection: FormEditSection = null;
  @Input() sections: SectionForm[] = [];
  @Input() tabGroupParent: MatTabGroup = null;
  @Output() processPanelHiddenEvent = new EventEmitter<boolean>();
  @Input() formEditfullname: string;
  @ViewChild(FormSectionComponent) child: FormSectionComponent;
  @ViewChild(FormEditComponent) formEdit: FormEditComponent;
  @ViewChild('inputFocus') inputFocus: ElementRef<HTMLElement>;

  isFromTask = false;
  acceptAttend = false;
  subRequestForm: SubRequestForm = null;
  currentFormContext: FormContext;
  selectedInstance: Instance;
  selectedContainer: InstanceContainer;
  isTable: any;
  isEditForm: any;
  _stopSubscriptions$ = new Subject<boolean>();
  @Input() disabledButton: false;

  constructor(private readonly formUtilitiesService: FormUtilitiesService, public readonly formDataService: FormDataService,
    private readonly functionsService: FunctionsService, private readonly originationsService: OriginationsService,
    private readonly globalSvc: GlobalService, private readonly alertService: AlertService, private readonly _dialog: MatDialog,
    private readonly elRef: ElementRef, private readonly _storage: FormStorageService, private maskPipe: MaskPipe,
    private readonly _formFileUploadSharedSrv: FormFileUploaderSharedService) {
    this.evaluateSectionBlocking();
    window['fieldfunctions'] = {};
  }

  ngOnInit() {
    this.formDataService.removeAllDatafrontElements();
    this.formDataService.removeAllDatafrontElementsHelper();
    this.formDataService.removeAllPartialFunctionListeners();
    this.formDataService.removeFormEditEmitters();
    this.formUtilitiesService.contextChanged$.pipe(takeUntil(this._stopSubscriptions$), distinctUntilChanged()).subscribe(context => {
      this.currentFormContext = context;
      this.isTable = context === FormContext.FORM_EDIT_TABLE;
      this.isEditForm = context === FormContext.FORM_EDIT_FORM;
    });
    this.selectedInstance = this.globalSvc.getInstance().getValue();
    this.selectedContainer = this.globalSvc.getContainer().getValue();
    this.isTable = this.currentFormContext === FormContext.FORM_EDIT_TABLE;
    this.isEditForm = this.currentFormContext === FormContext.FORM_EDIT_FORM;
    this.sections.forEach(section => {
      section.rows.forEach(row => {
        (row.elements as ElementsForm[]).forEach((element: ElementsForm) => {
          const copy = JSON.parse(JSON.stringify(element)) as ElementsForm;
          if (copy.elementType === FormElement.CHOICELIST_GROUP) {
            copy.fullName = `${copy.fullName}${copy.selectionIndex}`;
          } else if (copy.elementType === FormElement.PARTIAL_FUNCTION) {
            this.formDataService.addPartialFunction(element.fullName, new BehaviorSubject<boolean>(false));
          }
          this.formDataService.addDatafrontElement(element);
          this.formDataService.addDatafrontElementHelper(copy);
          if (copy.elementType === FormElement.FORM_EDIT) {
            this.formDataService.addFormEditFormGroup(copy.fullName);
          }
        });
      });
    });

    const inputFound = this.elRef.nativeElement.querySelector('.mat-input-element');
    if (inputFound) {
      this.elRef.nativeElement.querySelector('.mat-input-element')
        .addEventListener('focus', this.focusInput.bind(this));
    }
  }

  ngOnDestroy(): void {
    const queueFilesToDelete = Array.from(this._formFileUploadSharedSrv.queueFilesToDelete.values());
    const queueFilesToUpload = Array.from(this._formFileUploadSharedSrv.queueFilesToUpload.values());
    if (queueFilesToDelete.length) {
      this._formFileUploadSharedSrv.queueFilesToDelete.clear();
    }
    if (queueFilesToUpload.length) {
      this._formFileUploadSharedSrv.queueFilesToUpload.clear();
    }
    if (this.formDataService.activeFormEdit) {
      this.formDataService.getFormEditFormGroup(this.formDataService.activeFormEdit).reset();
    }
    this._stopSubscriptions$.next();
    this._stopSubscriptions$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentSections: SimpleChange = changes.sections;
    if (currentSections && !currentSections.isFirstChange() && !this.tabGroupParent) {
      this.processPanelHiddenEvent.emit(false);
      this.subRequestForm = null;
    }
    this.formDataService.isPollingForm = true;
  }

  ngAfterViewInit(): void {
    const customForm = this.formDataService.getContainerFormGroup();
    const autoSaveData = this.formDataService.getActualAutoSavedData();
    asap.schedule(() => {
      if (autoSaveData && customForm) {
        customForm.patchValue(autoSaveData);
      }
      asap.schedule(() => {
        this.formDataService.isPollingForm = false;
        if (this.formDataService.someFieldFunctionActivated && !this.globalSvc.getContainerDisabledStatus().getValue()) {
          this.formDataService.someFieldFunctionActivated = false;
          this._storage.pendingRequest = true;
          this._storage.manualSaveForm().subscribe(() => this._storage.pendingRequest = false);
        }
      }, 500);
    });
    this.callFunctionForm();
  }

  evaluateSectionBlocking(): void {
    this.isFromTask = (/true/i).test(this.functionsService.recoverValueStorage(DataTransfer.FROM_TASK));
    if (this.isFromTask) {
      this.globalSvc.formActionsAvailable.next(false);
    }
  }

  attendTask() {
    if (this.isFromTask && !this.acceptAttend) {
      const dialogRef = this._dialog.open(ModalTaskAttentionComponent, {
        width: '533px',
        disableClose: true,
        data: this.globalSvc.getInstance().getValue().instanceId
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.acceptAttend = true;
          this.functionsService.removeValueStorage(DataTransfer.FROM_TASK);
          this.originationsService.isInbox = false;
          this.globalSvc.formActionsAvailable.next(true);
          this.globalSvc.reloadContainer.next(this.formDataService.getActualContainerId());
        }
      });
    }
  }

  focusInput() {
    const blockElement = this.inputFocus;
    if (!blockElement) {
      return;
    }
    blockElement.nativeElement.focus();
    this.attendTask();
  }

  loadSubRequestForm(subRequestForm: any) {
    this.formDataService.isPollingForm = true;
    this.subRequestForm = subRequestForm;
    this.processPanelHiddenEvent.emit(true);
    setTimeout(() => (this.formDataService.isPollingForm = false), 800);
  }

  exitToForm() {
    if (this.currentFormContext === FormContext.FORM_EDIT_FORM) {
      this.formUtilitiesService.setCurrentFormContext(FormContext.SECTION_FORM);
    }
    this.isEditForm = false;
    this.formDataService.addFormEditFormGroup(this.formDataService.activeFormEdit);
    this.formDataService.activeFormEdit = undefined;
    this.formDataService.data = undefined;
    this.formDataService.index = undefined;
    asap.schedule(() => {
      this._storage.validateForm(this.formDataService.getContainerFormGroup(), true);
    }, 1000);
  }

  saveElementExit(exit: boolean) {
    const formEditMap = this.formDataService.getFormEditFormGroup(this.formDataService.activeFormEdit);
    const tableDataSource = this.formDataService.getTableDataSource(this.formDataService.activeFormEdit) as Array<any>;

    // Se convierte data a array para selects
    const formEditSections = this.formDataService.formEditSections.get(this.formDataService.activeFormEdit);
    let selects = [];
    const files = [];
    const dates = [];
    const masks = [];
    const htmls = [];
    const maps = []; //To add the maps controls and later make transformations

    formEditSections.forEach(section => {
      section.rows.forEach(row => {
        const tmpSelects = (row.elements as ElementsForm[]).filter(fil => fil.elementType === FormElement.CHOICELIST)
          .map(({ fullName }) => this.formDataService.getDatafrontElement(fullName))
          .map(({ fullName, selectionIndex, data }) => ({
            fullName,
            selectionIndex,
            parentKey: data[0].parentKey
          }));
        selects = [...selects, ...tmpSelects];
        files.push(...(row.elements as ElementsForm[])
          .filter(fil => fil.elementType === FormElement.ATTACHMENT || fil.elementType === FormElement.IMAGE));
        dates.push(...(row.elements as ElementsForm[])
          .filter(fil => fil.elementType === FormElement.DATE_PICKER_INLINE));
        masks.push(...(row.elements as ElementsForm[])
          .filter(fil => !!fil.mask && fil.mask !== 'none'));
        htmls.push(...(row.elements as ElementsForm[])
          .filter(fil => fil.elementType === FormElement.IFRAME));

        maps.push(...(row.elements as ElementsForm[])
          .filter(fil => fil.elementType === FormElement.MAP));
      });
    });

    selects.forEach(({ fullName }) => {
      const actualValue = formEditMap.get(fullName).value;
      if (Array.isArray(actualValue)) {
        return;
      }
      formEditMap.get(fullName).setValue([actualValue], { emitEvent: false, onlySelf: true });
    });

    const itemToAdd = formEditMap.getRawValue();
    // Zero case
    Object.keys(itemToAdd).forEach((prop: string) => {
      if (Array.isArray(itemToAdd[prop]) && (itemToAdd[prop][0] === null || itemToAdd[prop][0] === undefined)) {
        return itemToAdd[prop] = [];
      }
    });

    // Todos los tipos de dato para formedits deben tene run baseKeyName
    const dataTypeFormEdit = this.formDataService.getKeyNamesByFullNameMap().get(this.formDataService.activeFormEdit);
    const parentDatatype = this.formDataService.getDataTypesMap().get(dataTypeFormEdit);
    const childDataTyoe = this.formDataService.getDataTypesMap().get(parentDatatype.baseKeyName);
    completeModelWithDatatype(childDataTyoe, itemToAdd);
    // ---

    const existPrevItem = this.formDataService.getRawDataSource()[this.formDataService.index];
    if (existPrevItem) {
      itemToAdd.isSelected = existPrevItem.isSelected;
    }
    if (this.formDataService.index !== undefined) {
      this.formDataService.addItemFromDataSource(JSON.parse(JSON.stringify(itemToAdd)), this.formDataService.index);
      this.formDataService.addRawItemFromDataSource(JSON.parse(JSON.stringify(itemToAdd)), this.formDataService.index);
    } else {
      this.formDataService.addItemFromDataSource(JSON.parse(JSON.stringify(itemToAdd)));
      this.formDataService.addRawItemFromDataSource(JSON.parse(JSON.stringify(itemToAdd)));
    }

    const obj = {};
    obj[this.formDataService.activeFormEdit] = this.formDataService.getTableDataSource() || [];

    // Verifica estructura BlobFile
    obj[this.formDataService.activeFormEdit].forEach(form => {
      files.filter(item => !!form[item.fullName] && form[item.fullName].hasOwnProperty('FileName')
        && !!form[item.fullName].FileName).forEach(item => {

          const fileField = form[item.fullName];

          if (form[item.fullName].FileName.hasOwnProperty(VALUE_KEY)) {

            form[item.fullName].FileName = fileField.FileName.value;
            form[item.fullName].FileUrl = fileField.FileUrl.value;
            form[item.fullName].MimeType = fileField.MimeType.value;
          }

          //In case that file compound field is object that not have value then sent empty
          if (fileField.FileName && typeof fileField.FileName === OBJECT_TYPE && !fileField.FileName.hasOwnProperty(VALUE_KEY)) {

            form[item.fullName].FileName = '';
          }

          if (fileField.FileUrl && typeof fileField.FileUrl === OBJECT_TYPE && !fileField.FileUrl.hasOwnProperty(VALUE_KEY)) {

            form[item.fullName].FileUrl = '';
          }

          if (fileField.MimeType && typeof fileField.MimeType === OBJECT_TYPE && !fileField.MimeType.hasOwnProperty(VALUE_KEY)) {

            form[item.fullName].MimeType = '';
          }
        });
      dates.filter(item => item && form[item.fullName])
        .map(item => item.fullName)
        .forEach(item => {
          if (typeof form[item] === 'object') {
            form[item] = form[item].format('DD/MM/YYYY');
          } else if (typeof form[item] === 'string' && form[item].length > 10) {
            form[item] = momento(form[item]).format('DD/MM/YYYY');
          }
        });
      htmls.filter(item => item && form[item.fullName]).forEach(item => {

        const formField = form[item.fullName];
        if (formField.Url.hasOwnProperty('value')) {

          form[item.fullName] = { Url: formField.Url.value };
        }
      });

      // Check strucuture of maps and do transformations if it is necessary
      maps.filter(item => item && form[item.fullName]).forEach(item => {

        let latitude = form[item.fullName].Latitud;
        let longitude = form[item.fullName].Longitud;

        if (latitude.hasOwnProperty('value') && longitude.hasOwnProperty('value')) {

          form[item.fullName] = {
            Latitud: latitude.value,
            Longitud: longitude.value
          };
        }

      });
    });

    // OBTENEMOS EL NODO COMPONENTS DEL TIPO DE DATO EL CUAL NOS INDICARA SI ES SELECT SIMPLE O ANIDADO
    const components = childDataTyoe.components;
    Object.values(tableDataSource).forEach(sourceItem => {
      // ITERAMOS SOBRE LAS PROPIEDADES DEL ULTIMO REGISTRO A�ADIDO PARA IDENTIFICAR LOS SELECTS
      const newFields = {};
      const newSourceFieldsValues = {};
      Object.keys(sourceItem).forEach(key => {

        const data = components[key];
        if (Array.isArray(data.selection) && data.selection.length) {
          // OBTENEMOS LOS NOMBRES DE LOS CATALOGOS
          const catalogsName = data.selection.map(c => c.catalog);
          const transforms = [];

          // check if sourceItem[key] is not null or undefined when value is not yet added
          if (sourceItem[key]) {

            for (let selections = 0; selections < sourceItem[key].length; selections++) {
              const valueInSource = sourceItem[key][selections];

              if (!valueInSource) {

                continue;
              }

              const catalog = catalogsName[selections];
              const parentKey = typeof valueInSource === 'object' ? sourceItem[key][selections].key : sourceItem[key][selections - 1];
              const formatCatalogName = selections > 0 ? `${catalog}_${parentKey}` : catalog;
              const catalogList = this.formDataService.catalogsInMemory.get(formatCatalogName);

              if (!catalogList) {

                return;
              }

              // GUARDAMOS LO QUE ESTA EN LA POSICION
              const valueAtPosition = sourceItem[key][selections];
              // BUSCAMOS EN EL LISTADO LA OPCI�N SELECCIONADA POR EL USUARIO PARA OBTENER SU MODELO COMPLETO
              const model = catalogList.find(item => item['key'] === valueAtPosition);
              // VALIDAMOS SI EL ITEM TIENE O NO LA ESTRUCTURA
              const toSave = (model || (valueAtPosition && valueAtPosition['key'] && valueAtPosition));
              // GUARDAMOS LA ESTRUCTURA ENCONTRADA O LA QUE YA VENIA.

              if (toSave) {

                transforms.push(toSave['key'] || toSave);
              }
            }
          }

          newSourceFieldsValues[key] = transforms;
        }
      });
      // sourceItem = { ...sourceItem, ...newSourceFieldsValues, ...newFields };
      Object.assign(sourceItem, newSourceFieldsValues, newFields);
    });

    //Get the current index of edited row (undefined if is new row)
    const previousIndex = this.formDataService.index;

    this.functionsService.forceLoader(true);
    this.formDataService.checkMapAndFileStructure(obj[this.formDataService.activeFormEdit]);
    this.originationsService
      .partialSave(this.selectedInstance.instanceId, this.selectedContainer.containerId, obj)
      .subscribe({
        next: response => {
          this.functionsService.forceLoader(false);
          if (response.status === 0) {
            this.formDataService.addTableDataSource(this.formDataService.activeFormEdit, tableDataSource);
            this.formDataService.data = undefined;
            this.formDataService.index = undefined;

            formEditMap.reset();
            if (exit) {
              this.isEditForm = false;
              this.formDataService.addFormEditFormGroup(this.formDataService.activeFormEdit);
              this.formDataService.activeFormEdit = undefined;

              if (this.currentFormContext === FormContext.FORM_EDIT_FORM) {
                this.formUtilitiesService.setCurrentFormContext(FormContext.SECTION_FORM);
              }
            } else {
              this.alertService.success('Se guard� la informaci�n correctamente');
              this.formEdit.ngAfterViewInit();
            }
            asap.schedule(() => {
              this._storage.validateForm(this.formDataService.getContainerFormGroup(), true);
            }, 1000);
          } else if (response.status === 5) {

            //Check if is new element then remove it
            if (previousIndex == undefined) {

              tableDataSource.pop();
            }

            this.alertService.error('Ocurri� un error al guardar los datos.');
          } else if (response.status && this.functionsService.IsJsonString(response.error)) {

            //Check if is new element then remove it
            if (previousIndex == undefined) {

              tableDataSource.pop();
            }

            this.alertService.error('Ocurri� un error al guardar los datos.');
          }
        }
      });
  }

  isHiddenSubform(event) {
    if (this.subRequestForm && event) {
      const arrayForm = [];
      const controls = this.formDataService.getContainerFormGroup();
      this.subRequestForm.sections.forEach(item => {
        item.rows.forEach(row => {
          for (let i = 0; i < row.elements.length; i++) {
            arrayForm.push(row.elements[i]);
          }
        });
      });
      this.removeControl(controls, arrayForm, event);
    }
  }

  removeControl(controls: FormGroup, arrayForm: any, event: any) {
    for (let i = 0; i < Object.keys(controls.controls).length; i++) {
      for (let j = 0; j < arrayForm.length; j++) {
        if (event === true) {
          if (
            Object.entries(controls.controls)[i][0] === arrayForm[j].fullName
          ) {
            const fullName = arrayForm[j].fullName;
            Object.entries(controls.controls)[i][1].reset();
            controls.removeControl(fullName);
          }
        }
      }
    }
  }

  private callFunctionForm() {
    const arrayControls = {};
    const sections: SectionForm[] = this.formDataService.getSectionsGroup() || [];
    sections.forEach(section => {
      if (section.formEditFullname) {
        return;
      }
      const enableRule = section.enableRule;
      const funcItem = new Function(this.functionsService.b64DecodeUnicode(section.enableRule.fieldFunction));
      enableRule.fieldReferences.forEach(control => {

        const process = value => {

          arrayControls[control] = value;

          // if arrayControls have not the same objects as enableRule.fieldReferences get the values from the form of the missing fields to use in the function
          if (Object.keys(arrayControls).length !== enableRule.fieldReferences.length) {

            enableRule.fieldReferences.forEach(field => {

              if (!arrayControls[field]) {

                arrayControls[field] = this.formDataService.getControl(field).value;
              }
            });
          }

          const runFunct = funcItem.call(arrayControls);
          this.toggleShowElement(runFunct, section.selectionIndex);
        };

        const controlFound = this.formDataService.getContainerFormGroup().controls[control];
        const controlFormedit = this.formDataService.getFormEditEmitterByFullName(control);

        if (controlFound) {

          controlFound.valueChanges.pipe(takeUntil(this._stopSubscriptions$)).subscribe(process.bind(this));
        }
        else if (controlFormedit) {

          controlFormedit.pipe(takeUntil(this._stopSubscriptions$)).subscribe(process.bind(this));
        }
      });
    });
  }

  private toggleShowElement(runFunct, selectionIndex) {

    if (runFunct.hasOwnProperty(SECTION_SHOW_KEY)) {

      const sectionVisible = runFunct[SECTION_SHOW_KEY];
      let sectionData = {};

      if (sectionVisible) {

        // we need to get the data to can restore the values
        sectionData = this.formDataService.getActualAutoSavedData();
      }

      const selectedSection = this.sections.find(section => section.selectionIndex === selectionIndex);
      if (!selectedSection) {
        return;
      }
      selectedSection.isHidden = !runFunct['show'];
      selectedSection.rows.forEach((r: any) => r.elements.forEach(element => {
        let control: AbstractControl;
        control = this.formDataService.getControl(element.fullName);
        if (control) {

          if (!sectionVisible) {

            control.setValidators(null);
            this._resetByElementType(element, control);
          }
          else {

            if (element.isRequired) {

              control.setValidators(Validators.required);
            }

            if (control.value == null) {

              const controlOriginalValue = sectionData[element.fullName];
              control.patchValue(controlOriginalValue, { onlySelf: true, emitEvent: false });
            }

            control.updateValueAndValidity();
          }
        }
      }));
    }
  }

  private _resetByElementType(element: ElementsForm, control: AbstractControl): void {
    let formGroup, blank;
    const reset = value => control.patchValue(value, { onlySelf: true, emitEvent: true });
    switch (element.elementType) {
      case FormElement.CHOICELIST_GROUP:
        const formArr = control as FormArray;
        formArr.controls.forEach(s => {
          s.setValidators([]);
          s.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          s.patchValue(null);
        });
        break;
      case FormElement.ATTACHMENT:
      case FormElement.IMAGE:
        formGroup = control as FormGroup;
        blank = { FileName: null, FileUrl: null, MimeType: null };
        formGroup.reset(blank);
        this.formDataService.getActualAutoSavedData()[element.fullName] = blank;
        break;
      case FormElement.MAP:
        formGroup = control as FormGroup;
        blank = { Longitud: null, Latitud: null };
        formGroup.reset(blank);
        this.formDataService.getActualAutoSavedData()[element.fullName] = blank;
        break;
      default:
        reset(null);
    }
  }
}
