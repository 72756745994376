import { Component, OnInit } from '@angular/core';
import { Application } from 'src/app/shared/models';
import { ProfileService } from '../../services/profile.service';
import { EnvService } from '../../../core/services/env.service';
import { ApplicationType } from '../../enums';
import { Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { map, switchMap, take } from 'rxjs/operators';
import { PermissionsService } from '../../../core/services/permissions.service';
import { FolderTreeService } from '../../../modules/agents/pages/folder-tree/folder-tree.service';
import { ProfilePermissionsService } from '../../services/profile-permissions.service';
import { Observable, forkJoin } from 'rxjs';

// #region Constants

const OPTIONS: any = {
  reports: {
    id: 'e4dab23e-5978-4413-b5d6-88fa58a36401',
    title: 'Tablero de operaciones',
    iconUrl: '',
    materialIcon: 'assessment',
    type: ApplicationType.INTERNAL,
    url: '/administration/products/reports'
  },
  digitalOnboarding: {
    id: 'digital-onboarding-admin',
    title: '<b> Onboarding Digital </b> <p> Panel administrativo </p>',
    iconUrl: '',
    materialIcon: 'account_box',
    type: ApplicationType.INTERNAL,
    url: 'administration/digital-onboarding/users'
  },
  instancesReassignment: {
    id: 'instances-reassignment',
    title: 'Reasignación',
    iconUrl: '',
    materialIcon: 'contact_mail',
    type: ApplicationType.INTERNAL,
    url: 'administration/instances/instances-reassignment'
  },
  disbursementReport: {
    id: 'disbursement-report',
    title: 'Reporte de aceptaciones',
    iconUrl: '',
    materialIcon: 'bar_chart',
    type: ApplicationType.INTERNAL,
    url: 'administration/disbursement-report'
  },
};

// #endregion

@Component({
  selector: 'app-aplication-list',
  templateUrl: './aplication-list.component.html',
  styleUrls: ['./aplication-list.component.scss']
})
export class AplicationListComponent implements OnInit {
  types: typeof ApplicationType = ApplicationType;
  applications: Application[] = [];

  constructor(private readonly profileService: ProfileService, private readonly _folderSrv: FolderTreeService,
              private readonly _router: Router, private readonly _permissionSrv: PermissionsService,
              private readonly profilePermissionsService: ProfilePermissionsService) {
  }

  ngOnInit() {
    this.getAplications();
  }

  public openLink(url: string, external: boolean): void {
    if (external) {
      window.open(url);
    } else {
      this._router.navigate([url]);
    }
  }

  public getAplications() {
    this.profileService.getApplications().subscribe(data => {

      this.applications = data;
      this.getExtraOptions();
      this.allowAdministration();
      
      this.verifyOptionsPermissions();
    });
  }

  private getExtraOptions() {
    const optionsFound = window['__env'] as EnvService;
    if (optionsFound) {
      const extras = optionsFound.EXTRA_OPTIONS_MENU;
      if (!Array.isArray(extras)) return;
      this.applications = [...this.applications, ...extras.map(e => {
        return { title: e.label, materialIcon: 'open_in_new', type: ApplicationType.EXTERNAL, url: e.external } as Application;
      })];
    }
  }

  private allowAdministration(): void {
    const optionsFound = window['__env'] as EnvService;
    if (optionsFound) {
      const extras = optionsFound.ADMINISTRATION_OPTION;
      const removeOption = () => {
        this.applications.splice(this.applications.findIndex(option => option.id === 'e4dab23e-5978-4413-b5d6-88fa58a36400'), 1);
        this._permissionSrv.permissions = [];
        this._permissionSrv.savePermissions();
      };
      if (!extras) {
        return;
      } else if (!Boolean(extras.show)) {
        removeOption();
      } else if (extras.show && Array.isArray(extras.roles) && extras.roles.length) {
        this.profileService.userProfile$.pipe(filter(user => !!user), map(user => user.roles)).subscribe((roles: string[]) => {
          const isIncluded = roles.some(rol => extras.roles.includes(rol));
          if (!isIncluded) removeOption();
        });
      }
    }
  }

  private allowOperationalBoard(): void {
    const optionsFound = window['__env'] as EnvService;
    if (optionsFound && Array.isArray(optionsFound.ALLOW_OPERATION_BOARD_BY_TAGS) && optionsFound.ALLOW_OPERATION_BOARD_BY_TAGS.length) {
      this.profileService.userProfile$.pipe(
        filter(agent => !!agent && !!agent.id),
        take(1),
        map(agent => agent.id),
        switchMap(agentId => this._folderSrv.getByAgentId(agentId).pipe(
          filter(agentInfo => agentInfo && Array.isArray(agentInfo) && agentInfo.length > 0),
          map(agentInfo => agentInfo[0].agent.labels)
        ))).subscribe((tags: string[]) => {
        
        const tagsByEnv = optionsFound.ALLOW_OPERATION_BOARD_BY_TAGS;
        const allowBoard = tagsByEnv.some(tag => tags.includes(tag));
        
        if (allowBoard) {
          this.applications.push({
            id: 'e4dab23e-5978-4413-b5d6-88fa58a36401',
            title: 'Tablero de operaciones',
            iconUrl: '',
            materialIcon: 'assessment',
            type: ApplicationType.INTERNAL,
            url: '/administration/products/reports'
          });
        }
        this.profileService.applications = JSON.parse(JSON.stringify(this.applications));
      });
    }
  }

  private verifyOptionsPermissions(): void {

    // Create observables for each permission check
    const reportsPermissionObserver: Observable<boolean> = this.profilePermissionsService.userHasReportsPermission();
    const digitalOnboardingPermissionObserver: Observable<boolean> = this.profilePermissionsService.userHasDigitalOnboardingAdminPermission();
    const instancesReassignmentPermissionObserver: Observable<boolean> = this.profilePermissionsService.userHasInstancesReassignmentPermission();
    const disbursementReportPermissionObserver: Observable<boolean> = this.profilePermissionsService.userHasDisbursementReportPermission();

    // Use forkJoin to wait for both observables to complete
    forkJoin([reportsPermissionObserver, digitalOnboardingPermissionObserver, instancesReassignmentPermissionObserver, disbursementReportPermissionObserver ]).subscribe(
      ([hasReportsPermission, hasDigitalOnboardingPermission, instancesReassignmentPermission, disbursementReportPermission ]: [boolean, boolean, boolean, boolean]) => {

        if (hasReportsPermission) {

          this.applications.push(OPTIONS.reports);
        }

        if (hasDigitalOnboardingPermission) {
          
          this.applications.push(OPTIONS.digitalOnboarding);
        }

        if (instancesReassignmentPermission) {
          
          this.applications.push(OPTIONS.instancesReassignment);
        }

        if (disbursementReportPermission) {
          
          this.applications.push(OPTIONS.disbursementReport);
        }
        // Save a copy of the applications in the profile service
        this.profileService.applications = JSON.parse(JSON.stringify(this.applications));
      }
    );
  }
}
